/*
 * This file contains all styles related to the contact section of the site/application.
 */

#contact {
  position: relative;
  background-color: white;
  height: 100%;
}

.contact {
  text-transform: uppercase;
  position: relative;

  h3 {
    @include h3-style($black);
    padding: 3rem $mobile-side-padding;

    @include for-tablet-landscape-up {
      padding: 3rem $desktop-side-padding;
    }
  }

  .boxes {
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include for-tablet-landscape-up {
      flex-direction: row;
    }
  }
}

.contact__dark {
  flex: 1;
  color: white;
  background: black;
  text-align: right;

  h4 {
    font-size: 2rem;
    font-weight: 300;
    padding: 2rem 3rem 1rem 0;
  }

  .contact__dark__contact-details {
    padding: 1rem 0 2rem 0;
    border-top: 2px solid white;
    margin-right: 3rem;
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 3rem;
    letter-spacing: 2px;

    .contact__dark__telno {
      text-decoration: none;
      color: white;

      &link {
        text-decoration: none;
        color: white;
      }
    }
  }
}

.contact__light {
  flex: 1;
  color: $black;
  background: $white;
  text-align: left;

  .contact__icon__wrapper {
    padding: 2rem 0 1rem 3rem;
  }

  .contact__icon {
    color: black;
    height: 2.8rem;
    width: 2.8rem;
    margin-left: -0.25rem;
  }

  .contact__light__dm-wrapper {
    margin-left: 3rem;
    border-top: 2px solid black;
    padding: 1rem 0 2rem 0;
  }

  p {
    width: 80%;
    font-size: 1.5rem;
    font-weight: 200;

    @include for-tablet-portrait-up {
      width: 60%;
    }
  }
}
