/*
 * This file contains all styles related to the services section of the site/application,
 * exluding the styling for the modal pop-outs.
 */

#services {
  position: relative;
  background-color: $white;
  height: 100%;
}

.services__slant {
  position: absolute;
  height: 35vh;
  width: 100%;
  background-color: $lightgray;
  z-index: 1;

  @include for-tablet-portrait-up {
    height: 40vh;
  }

  &:after {
    bottom: 0;
    position: absolute;
    background: inherit;
    content: " ";
    height: 100%;
    left: 0;
    right: 0;
    transform: skewY(-7deg);
    transform-origin: 100%;
    z-index: 1;
  }
}

.services {
  position: relative;
  padding: 0 $mobile-side-padding;
  padding-bottom: 4rem;
  z-index: 2;

  @include for-tablet-portrait-up {
    padding: 0 $desktop-side-padding;
    padding-bottom: 4rem;
  }

  h3 {
    @include h3-style($black);
  }
}

.service__list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 3rem;

  @include for-tablet-landscape-up {
    grid-template-columns: 26vw 28vw 26vw;
    column-gap: 0;
  }
}

.service__item {
  display: flex;
  flex-direction: column;

  @include for-tablet-landscape-up {
    &:first-child,
    &:nth-child(4) {
      padding-right: 2vw;
    }

    &:nth-child(3),
    &:last-child {
      padding-left: 2vw;
      border-left: $fd-border;
    }

    &:nth-child(2),
    &:nth-child(5) {
      padding: 0 2vw;
      border-left: $fd-border;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  .service__item__heading {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 400;
    padding-top: 1rem;

    @include for-tablet-landscape-up {
      font-size: 1.8rem;
      padding-top: 1rem;
    }
  }

  .service__item__description {
    @include p-style($black);
    padding: 1rem 0 1rem 0;
    line-height: 1.2rem;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .service__item__starting {
    padding-bottom: 1.2rem;
    font-weight: 400;
    line-height: 1.1rem;
    font-size: 0.8rem;
    letter-spacing: 1px;
    text-transform: uppercase;

    @include for-tablet-landscape-up {
      line-height: 2rem;
      font-size: 1rem;
    }
  }

  .service__item__category {
    padding: 0.5rem 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 400;
    border-bottom: $fd-border;

    @include for-tablet-landscape-up {
      font-size: 1rem;
    }
  }

  .service__item__bottom {
    margin-top: auto;
  }

  .service__item__more-link {
    a {
      text-decoration: none;
      color: black;
      font-weight: 500;
      font-size: 1.2rem;
      text-transform: uppercase;
      transition: all 0.3s ease 0s;

      &:hover {
        color: white;
        background-color: black;
        padding-left: 0.2rem;
        padding-right: 0.2rem;

        span {
          border: none;
        }
      }

      span {
        letter-spacing: 1px;
        padding-bottom: 5px;
        border-bottom: $fd-border;
        margin-right: 0.5rem;
      }
    }
  }
}
