/*
 * This file contains all styles related to the footer section of the site/application.
 */

footer {
  position: relative;
  background-color: #d8d8d8;
}

.copyright {
  font-size: 0.8rem;
  letter-spacing: 1px;
  padding: 0.5rem 0;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  background-color: white;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem $mobile-side-padding;
  position: relative;

  @include for-tablet-landscape-up {
    flex-direction: row;
    padding: 1.5rem $desktop-side-padding;
  }

  .footer__logo__container {
    padding-bottom: 1.5rem;
    flex: 1;
    text-align: center;

    @include for-tablet-landscape-up {
      padding: 0;
      border: none;
      text-align: left;
    }

    .footer__logo {
      height: auto;
      width: 30%;

      @include for-tablet-landscape-up {
        width: 70%;
      }
    }
  }

  address {
    text-transform: uppercase;
    font-style: normal;
    line-height: 2rem;
    flex: 1;
    font-weight: 300;
    text-align: center;
    padding: 1rem 0;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    padding: 1.5rem 0 1.5rem 0;
    width: 100%;

    @include for-tablet-landscape-up {
      padding: 0;
      border: none;
    }
  }

  nav {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;

    .footer__nav {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 1.5rem;

      @include for-tablet-landscape-up {
        padding: 0;
      }

      li {
        text-align: center;
        list-style: none;

        &:not(:first-child) {
          padding-top: 0.5rem;
        }
      }

      a {
        color: black;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}
