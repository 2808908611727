/* 
 Reset all padding and margins to be
 consistent across browsers
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 1rem;
  /* Smooth scroll ensures any link clicks will scroll
   * to desired location rather than jumping directly */
  scroll-behavior: smooth;
}
