/*
 * This file contains all styles related to the about section of the site/application,.
 */

#about {
  position: relative;
  background-color: white;
  height: 100%;
}

.about {
  position: relative;
  padding: 2rem $mobile-side-padding 0 $mobile-side-padding;

  @include for-tablet-landscape-up {
    padding: 8rem $desktop-side-padding 0 $desktop-side-padding;
  }

  h3 {
    @include h3-style($black);
    padding-top: 6rem;
  }

  .about__profile {
    display: grid;
    grid-template-columns: 1fr;

    @include for-tablet-landscape-up {
      grid-template-columns: 1fr 1fr;
    }
  }

  .about__profile__pic {
    position: relative;
    height: auto;
    width: 100%;

    @include for-tablet-landscape-up {
      border-right: 2px solid black;
      padding-right: 2rem;
    }
  }

  .about__profile__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-top: 1rem;

    @include for-tablet-landscape-up {
      padding-left: 2rem;
    }
  }

  .about__profile__description {
    @include p-style($black);
    font-size: 0.7rem;
    line-height: 1.2rem;
    padding-top: 1rem;
  }

  .about__profile__name {
    font-size: 1.2rem;
    border-bottom: 2px solid black;
    text-transform: uppercase;
    padding-bottom: 1rem;
    font-weight: 400;
  }

  .about__profile__name-wrapper {
    display: flex;
    width: auto;
  }
}
