/*
 * This file contains all styles related to the pop-out
 * modal gallery section of the site/application.
 */

.gallery__modal__container {
  position: relative;
  z-index: 9999;
}

/* The Modal (background) */
.image-modal {
  display: none;
  position: fixed;
  z-index: 9999;
  padding: 10vh 10% 1rem 10%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);

  @include for-tablet-landscape-up {
    padding: 12vh $desktop-side-padding 1rem $desktop-side-padding;
  }
}

/* Modal Content */
.gallery__modal__content {
  position: relative;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @include for-tablet-landscape-up {
    display: grid;
    grid-template-columns: 1fr 2fr 20fr 2fr 2fr 2fr;
  }

  .modal-main {
    order: 3;
    justify-self: center;
    position: relative;
    width: auto;

    @include for-tablet-landscape-up {
      order: unset;
    }

    .hidden {
      display: none;
    }

    .visually-hidden {
      opacity: 0;
    }

    /* Hide the slides by default */
    .gallerySlides {
      width: auto;
      transition: all 1s linear;

      img {
        height: auto;
        max-height: 80vh;
        width: 100%;
        object-fit: contain;
      }
    }

    /* Caption text */
    .gallery__modal__caption-container {
      text-align: center;
      color: white;
      padding-top: 0.5rem;
    }
  }

  .thumbnails {
    display: none;
    height: 80vh;
    max-height: 80vh;
    //display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;
    background-color: transparent;

    @include for-tablet-landscape-up {
      display: flex;
    }

    img {
      align-self: center;
      height: auto;
      width: 80%;
      min-width: 3rem;
      opacity: 0.6;
      cursor: pointer;

      &:hover {
        opacity: 1;
      }
    }

    .active {
      opacity: 1;
    }
  }

  /* Next & previous buttons */
  #gallery__modal__prev,
  #gallery__modal__next {
    display: none;
    cursor: pointer;
    transition: 0.6s ease;
    height: 2rem;
    width: 2rem;
    color: white;

    &:hover,
    &:focus {
      color: #999;
      text-decoration: none;
      cursor: pointer;
    }

    @include for-tablet-landscape-up {
      display: block;
    }
  }
  #gallery__modal__prev {
    justify-self: right;
  }
  #gallery__modal__next {
    justify-self: left;
  }

  /* The Close Button */
  #gallery__modal__close {
    order: 1;
    color: white;
    height: 2rem;
    width: 2rem;
    align-self: flex-end;
    margin-right: -0.25rem;
    margin-bottom: 1rem;

    &:hover,
    &:focus {
      color: #999;
      text-decoration: none;
      cursor: pointer;
    }

    @include for-tablet-landscape-up {
      order: unset;
      align-self: start;
      justify-self: right;
      margin-top: -0.25rem;
    }
  }

  /* Number text (1/3 etc) */
  #imageCount {
    order: 2;
    color: #f2f2f2;
    align-self: flex-start;
    padding-bottom: 0.25rem;
    font-size: 1rem;
    white-space: nowrap;

    @include for-tablet-landscape-up {
      order: unset;
      padding-bottom: 0;
      padding-right: 0.5rem;
    }
  }
}
