/*
 * This file contains all styles related to the pop-out service modals.
 */

.click-me {
  text-decoration: underline;
  cursor: pointer;
}

.service__modal {
  position: relative;
  z-index: 996;
}

.service__modal___overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.service__modal__container {
  position: relative;
  z-index: 997;
  background-image: linear-gradient(white, lightgray, grey);
  width: 90%;
  height: auto;
  max-height: 90%;
  overflow-y: auto;

  @include for-tablet-portrait-up {
    width: 70%;
    max-height: 80%;
  }
}

.service__modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.service__modal__title {
  padding-top: 0;
  font-weight: 400;
  font-size: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: black;

  @include for-tablet-landscape-up {
    font-size: 3rem;
    line-height: 3rem;
  }
}

.service__modal__close {
  align-self: flex-start;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.service__modal__header .service__modal__close:before {
  content: "\2715";
}

.service__modal__content {
  position: relative;
  height: 100%;
  padding: 1.2rem 1.2rem 0 1.2rem;
  z-index: 999;

  @include for-tablet-landscape-up {
    padding: 2rem 2rem 0rem 2rem;
  }

  .service__modal__main {
    padding-top: 2rem;
    height: auto;

    @include for-tablet-landscape-up {
      display: grid;
      grid-template-columns: 1.2fr 1fr;
      column-gap: 2rem;
    }

    .service__modal__wide {
      @include for-tablet-landscape-up {
        grid-column: 1/3;
      }
    }

    .service__modal__description {
      @include p-style($black);
      font-size: 1rem;
      line-height: 1.3rem;
      display: flex;
      align-items: center;
      padding-bottom: 1.5rem;
    }
  }

  .service__modal__main__single {
    @extend .service__modal__main;

    @include for-tablet-landscape-up {
      grid-template-columns: 1fr;
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }

  .modal__service-description {
    line-height: 1rem;
    font-weight: 300;
    font-size: 1rem;
    padding-bottom: 1.5rem;

    @include for-tablet-landscape-up {
      line-height: 2rem;
    }
  }

  .service__modal__included {
    padding-bottom: 1.5rem;
    line-height: 2rem;

    @include for-tablet-landscape-up {
      line-height: 2.5rem;
    }

    h6 {
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 400;

      @include for-tablet-landscape-up {
        font-size: 2rem;
      }
    }

    .service__modal__included__info-row {
      font-size: 0.7rem;
      padding-left: 1.3rem;
      margin-top: -0.7rem;
      font-style: italic;

      @include for-tablet-landscape-up {
        padding-left: 2rem;
        font-size: 0.8rem;
        margin-top: -1rem;
      }
    }

    .service__modal__included__add-extras {
      margin-top: 1rem;
      padding: 0.8rem;
      text-transform: uppercase;
      line-height: 1.5rem;
      font-size: 1.5rem;
      font-weight: 400;
      width: 100%;
      background-color: black;
      color: white;
      text-align: center;

      @include for-tablet-landscape-up {
        font-size: 1.5rem;
      }
    }

    ul {
      padding-top: 0.5rem;
    }

    li {
      text-transform: uppercase;
      font-weight: 300;
      font-size: 1rem;
      list-style: none;

      @include for-tablet-landscape-up {
        font-size: 1.2rem;
        padding-right: 1rem;
      }

      .tick {
        padding-right: 0.5rem;

        @include for-tablet-landscape-up {
          padding-right: 1rem;
        }
      }
    }
  }

  .service__modal__process {
    line-height: 1.2rem;

    h6 {
      padding-top: 0.3rem;
      text-transform: uppercase;
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-weight: 500;
    }

    ol {
      padding-top: 1rem;
      counter-reset: custom-counter;
      list-style: none;

      @include for-tablet-landscape-up {
        padding-bottom: 2rem;
      }

      li {
        counter-increment: custom-counter;
        position: relative;
        list-style: none;
        font-weight: 300;
        font-size: 0.8rem;
        padding-top: 0.2rem;
        padding-left: 1.3rem;

        &:before {
          margin-left: 1rem;
          margin-top: 0.2rem;
          position: absolute;
          content: counter(custom-counter);
          color: white;
          font-size: 0.9rem;
          --size: 1.3rem;
          left: calc(-1 * var(--size));
          line-height: var(--size);
          width: var(--size);
          height: var(--size);
          top: 0;
          background: black;
          border-radius: 50%;
          text-align: center;
        }
      }
    }
  }

  .service__modal__cta {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-self: center;
    text-align: center;
    padding-bottom: 1.5rem;

    .service__modal__details {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 300;
      padding-bottom: 1.5rem;
      border-bottom: $fd-border;
      line-height: 1.2rem;

      .brackets {
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 300;
      }

      @include for-tablet-landscape-up {
        text-transform: uppercase;
        font-size: 1.7rem;
        font-weight: 300;
        padding-bottom: 1.5rem;
        border-bottom: $fd-border;
        line-height: 1.9rem;

        .brackets {
          text-transform: uppercase;
          font-size: 1.2rem;
          font-weight: 300;
        }
      }
    }

    .service__modal__price {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 200;
      padding-top: 1.5rem;

      @include for-tablet-landscape-up {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 200;
        padding-top: 1.3rem;
      }
    }

    .service__modal__contact {
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 400;

      a {
        color: black;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 400;
        text-decoration: underline;
      }

      @include for-tablet-landscape-up {
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }

  .price-list__container {
    padding-bottom: 1.2rem;
    padding-top: 1.5rem;
    width: 100%;

    @include for-tablet-landscape-up {
      padding-top: 0;
      padding-bottom: 2rem;
    }
  }
}

/**************************\
    Demo Animation Style
  \**************************/
@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .service__modal___overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .service__modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .service__modal___overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .service__modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .service__modal__container,
.micromodal-slide .service__modal___overlay {
  will-change: transform;
}
