/*
 * This file contains all styles related to the gallery section of the site/application,
 * exluding the styling for the pop-out gallery overlay.
 */

#gallery {
  position: relative;
  background-color: black;
}

.gallery__slant-top {
  position: absolute;
  height: 30vh;
  width: 100%;
  background-color: rgba($color: #212121, $alpha: 1);
  transform: translateY(-70%);
  z-index: 1;
  &:after {
    bottom: 0;
    position: absolute;
    background: inherit;
    content: " ";
    height: 100%;
    left: 0;
    right: 0;
    transform: skewY(7deg);
    transform-origin: 100%;
    z-index: 1;
  }
}

.gallery__slant-bottom {
  position: absolute;
  height: 20vh;
  width: 100%;
  background-color: rgba($color: #212121, $alpha: 1);
  transform: translateY(-50%);
  z-index: 1;

  @include for-tablet-portrait-up {
    height: 30vh;
  }

  &:after {
    bottom: 0;
    position: absolute;
    background: inherit;
    content: " ";
    height: 100%;
    left: 0;
    right: 0;
    transform: skewY(7deg);
    transform-origin: 0%;
    z-index: 1;
  }
}

.gallery {
  position: relative;
  padding: 0 $mobile-side-padding;
  z-index: 2;

  @include for-tablet-portrait-up {
    padding: 0 $desktop-side-padding;
  }

  h3 {
    @include h3-style($white);
  }
}

.gallery__item__title {
  display: none;

  @include for-tablet-landscape-up {
    display: block;
    color: white;
    padding: 0.5rem 0;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
  }
}

.gallery__item__description {
  display: none;

  @include for-tablet-landscape-up {
    display: block;
    @include p-style($white);
  }
}

.gallery__list {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1rem;
  row-gap: 1rem;

  @include for-tablet-landscape-up {
    grid-template-columns: repeat(3, auto);
    column-gap: 2rem;
    row-gap: 2rem;
  }
}

.gallery__mobile-row-line {
  border-top: 2px solid white;
  grid-column: 1 / span 2;

  @include for-tablet-landscape-up {
    display: none;
  }
}

.gallery__desktop-row-line {
  display: none;

  @include for-tablet-landscape-up {
    grid-column: 1 / span 3;
    border-top: 2px solid white;
  }
}

.gallery__item {
  position: relative;

  &:nth-child(4) {
    grid-column: 1 / span 2;
  }

  &:last-child {
    grid-column: 1 / span 2;
  }

  @include for-tablet-landscape-up {
    &:nth-child(4) {
      grid-column: unset;
    }

    &:last-child {
      grid-column: unset;
    }
  }

  img {
    width: 100%;
    height: auto;
    transition: all 0.5s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.03);
    }
  }
}
