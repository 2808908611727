.price-list__heading {
  color: black;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 1px;
  padding-bottom: 1rem;
  border-bottom: 2px solid black;
}

.price-list__row {
  padding-top: 1.2rem;
  display: flex;
  justify-content: space-between;
}

.price-list__item__title {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.price-list__item__description {
  font-weight: 200;
}
