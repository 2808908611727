.blink {
  -webkit-animation: blink-animation 3s infinite;
  animation: blink-animation 3s infinite;
}
@keyframes blink-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.invert {
  filter: invert(100%);
}
