/*
 * This file contains all styles related to the home section of the site/application.
 */

#title {
  position: relative;
  background-color: $black;
  height: 100%;
  min-height: 92vh;
}

.home {
  position: relative;
  height: 100%;
  min-height: 92vh;
  padding: 15vh $mobile-side-padding;
  color: white;
  text-align: center;

  @include for-tablet-landscape-up {
    padding: 10vh $desktop-side-padding;
  }

  img {
    height: 100%;
    width: 100%;

    @media (min-width: 550px) and (orientation: landscape) {
      height: 70%;
      width: 70%;
    }
    @include for-tablet-landscape-up {
      height: auto;
      width: auto;
      max-height: 45vh;
      max-width: 100%;
    }
  }

  h3 {
    padding-top: 1rem;
    letter-spacing: 2px;
    font-size: 6vw;
    font-weight: 400;

    @media (min-width: 550px) and (orientation: landscape) {
      font-size: 3vw;
    }
    @include for-tablet-landscape-up {
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 4vw;
    letter-spacing: 2px;
    font-weight: 200;

    @media (min-width: 550px) and (orientation: landscape) {
      font-size: 2vw;
    }
    @include for-tablet-landscape-up {
      font-size: 1.5rem;
    }
  }

  .down-arrow {
    display: none;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -1.5rem;
    margin-bottom: 2rem;
    height: 3rem;
    width: 3rem;

    @include for-tablet-landscape-up {
      display: block;
    }
  }
}
