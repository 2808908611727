/* Padding */
$desktop-side-padding: 10%;
$mobile-side-padding: 5%;

/* Colours */
$white: #ffffff;
$lightlightgray: #fafafa;
$lightgray: #eeeeee;
$black: #000000;

/* Styling */
$fd-border: 2px solid $black;
