/*
 * This file contains all styles related to the sticky header section of the site/application.
 */

/* Wrapper for header to force stickyness */
.sticky {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 8vh;
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
  padding: 0 $mobile-side-padding;
  height: 8vh;
  min-height: 50px;

  @include for-tablet-landscape-up {
    padding: 0 $desktop-side-padding;
  }
}

/* Screen reader only */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.header__logo__container {
  display: flex;
  align-items: center;

  .header__logo {
    display: flex;
    align-items: center;

    img {
      height: 6vh;
      width: auto;
    }
  }

  .header__logo__icon__wrapper {
    margin-left: 1rem;
  }

  .header__logo__icon {
    height: 2.5rem;
    width: 2.5rem;
    color: black;
    vertical-align: -0.25rem;
  }
}

/* Button styling */
.menu-toggle {
  display: inline-block;
  line-height: 1.8rem;
  font-size: 1.8rem;
  color: black;
  transition: all 0.3s ease 0s;
  background: none;

  @include for-tablet-landscape-up {
    display: none;
  }

  &:hover {
    color: grey;
  }

  .header__hamburger {
    height: 1.8rem;
    width: 2rem;
    margin-right: -0.2rem;
    cursor: pointer;
  }
}

/*
 Default styles + Mobile first
 Offscreen menu style
*/
.main-menu {
  position: absolute;
  right: -200px;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: visible;
  transition: right 0.3s ease, box-shadow 0.3s ease;
  z-index: 999;

  @include for-tablet-landscape-up {
    position: relative;
    right: auto;
    top: auto;
    height: auto;
    display: block;
    width: 50%;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 8vh 0 0;
    /* Hide shadow w/ -8px while 'closed' */
    -webkit-box-shadow: -8px 0 8px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: -8px 0 8px rgba(0, 0, 0, 0.5);
    box-shadow: -8px 0 8px rgba(0, 0, 0, 0.5);
    min-height: 100%;
    width: 200px;
    background: black;

    @include for-tablet-landscape-up {
      display: flex;
      justify-content: space-between;

      /* Undo off-canvas styling */
      padding: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      height: auto;
      width: auto;
      background: none;
    }
  }

  /* Decorate all menu a elements to be the same - including close button */
  a {
    display: block;
    padding: 0.75rem 0.75rem;
    line-height: 1rem;
    font-size: 1rem;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.3s ease 0s;

    @include for-tablet-landscape-up {
      color: black;
      border: 0 !important; /* Remove borders from off-canvas styling */
    }
  }

  /* Line below all li menu elements - not close button */
  li {
    &:first-child a {
      border-top: 1px solid white;
    }

    a {
      padding-left: 1rem;
      border-bottom: 1px solid white;

      &:hover,
      &:focus {
        background: darkgrey;

        @include for-tablet-landscape-up {
          background-color: $black;
          color: $white;
          padding: 1rem;
        }
      }
    }

    @include for-tablet-landscape-up {
      margin: auto 0;
    }
  }

  .menu-close {
    font-size: 1.5rem;
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 1rem;
    padding-right: 1rem;

    @include for-tablet-landscape-up {
      display: none;
    }

    &:hover,
    &:focus {
      color: grey;
    }

    .header__menu__close {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: -0.125rem;
      margin-top: -0.125rem;
    }
  }

  /*
   * On small devices, allow it to toggle...
   *
   * :target for non-JavaScript
   * [aria-expanded] will be used if/when JavaScript is added to improve interaction, 
   * though it's completely optional.
   */
  &:target,
  &[aria-expanded="true"] {
    display: block;
    right: 0;
    outline: none;
    -moz-box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);
    box-shadow: 3px 0 12px rgba(0, 0, 0, 0.25);

    .menu-close {
      z-index: 1001;
    }

    ul {
      position: relative;
      z-index: 1000;
    }

    /* 
     * We could us `.main-menu:target:after`, but
     * it wouldn't be clickable.
     */
    + .backdrop {
      position: absolute;
      display: block;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 998;
      background: #000;
      background: rgba(16, 16, 16, 0.85);
      cursor: default;
    }
  }
}

@supports (position: fixed) {
  .main-menu,
  .main-menu:target + .backdrop,
  .main-menu[aria-expanded="true"] + .backdrop {
    position: fixed;

    @include for-tablet-landscape-up {
      position: relative;
    }
  }
}
