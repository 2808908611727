@mixin for-phone-only {
  @media (max-width: 549px) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 550px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin h3-style($color) {
  font-size: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-weight: 500;
  color: $color;
  text-transform: uppercase;

  @include for-tablet-landscape-up {
    font-size: 4rem;
    letter-spacing: 4px;
  }
}

@mixin p-style($color) {
  font-family: Roboto;
  color: $color;
  font-weight: 200;
  font-size: 0.8rem;
  line-height: 1.2rem;
}
